import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {InMemoryDataService} from './shared/dataservice';
import {HttpClientInMemoryWebApiModule} from 'angular-in-memory-web-api';
import {ModalComponent} from './modal/modal.component';
import {CustomInterceptor} from './shared/http.interceptor';
import {AppRoutingModule} from './app-routing.module';
import { ModalTapListComponent } from './modal-tap-list/modal-tap-list.component';
import {ModalService} from './modal/modal.service';
import { NavbarComponent } from './shared/navbar/navbar.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import { AboutUsComponent } from './about-us/about-us.component';
import localeIt from '@angular/common/locales/it';
import localeItExtra from '@angular/common/locales/extra/it';
import {registerLocaleData} from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {SwitchTranslationComponent} from './switch-translation/switch-translation.component';
import {ContactComponent} from './contact/contact.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import {environment} from '../environments/environment';
import { FooterComponent } from './shared/footer/footer.component';
import { UnderMaintenanceComponent } from './under-maintenance/under-maintenance.component';
import {AuthGuardService} from './shared/services/authguard.service';
import { NotFoundComponent } from './not-found/not-found.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

registerLocaleData(localeIt, 'it-IT', localeItExtra);

@NgModule({
  declarations: [
    AppComponent,
    MapComponent,
    ModalComponent,
    ModalTapListComponent,
    NavbarComponent,
    AboutUsComponent,
    SwitchTranslationComponent,
    ContactComponent,
    FooterComponent,
    UnderMaintenanceComponent,
    NotFoundComponent
  ],
    imports: [
        BrowserModule,
        HttpClientModule,
        AppRoutingModule,
        RecaptchaV3Module,
        FontAwesomeModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (HttpLoaderFactory),
                deps: [HttpClient]
            }
        }),
        HttpClientInMemoryWebApiModule.forRoot(
            InMemoryDataService, {dataEncapsulation: false, passThruUnknownUrl: true}
        ),
        ReactiveFormsModule
    ],
  providers: [
    AuthGuardService,
    ModalService,  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomInterceptor,
    multi: true
  },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.recaptcha.siteKey,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
