import { Component, OnInit } from '@angular/core';
import { faLocationPin } from '@fortawesome/free-solid-svg-icons';
import { faMailBulk } from '@fortawesome/free-solid-svg-icons';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import * as emailjs from '@emailjs/browser';
import {ContactService} from '../shared/services/contact.service';
import {ReCaptchaV3Service} from 'ng-recaptcha';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  faLocationPin = faLocationPin;
  faEmail = faMailBulk;
  faInstagram = faInstagram;
  faFacebook = faFacebook;
  contactForm: FormGroup;
  emailError: boolean;
  emailSent: boolean;
  url: string;

  constructor(private fb: FormBuilder, private contactService: ContactService, private recaptchaV3Service: ReCaptchaV3Service) { }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      name: new FormControl ('', [Validators.required, Validators.minLength(2), Validators.pattern(/^[^ ][\w\W ]*[^ ]/)]),
      email: new FormControl ('',  [Validators.required, Validators.email, Validators.pattern(/^[^ ][\w\W ]*[^ ]/)]),
      subject: new FormControl ('', [Validators.required, Validators.minLength(5), Validators.pattern(/^[^ ][\w\W ]*[^ ]/)]),
      message: new FormControl ('',  [Validators.required, Validators.minLength(20), Validators.pattern(/^[^ ][\w\W ]*[^ ]/)])
    });
    this.emailError = false;
    this.emailSent = false;

    this.contactService.getNgrokUrl().subscribe(res => {
      this.url = Object.keys(res).map(e => res[e])[0].url;
    });
  }

  get name() { return this.contactForm.get('name'); }

  get email() { return this.contactForm.get('email'); }

  get subject() { return this.contactForm.get('subject'); }

  get message() { return this.contactForm.get('message'); }

  public sendEmail(e: Event) {
    const send =  document.getElementById('send') as HTMLButtonElement;
    this.emailError = false;
    this.emailSent = false;
    send.disabled = true;
    this.recaptchaV3Service.execute('sendEmail')
      .subscribe((token: string) => {
        const body = {
          token
        }
        this.contactService.checkRecaptcha(this.url, body).subscribe(res => {
            if (res['google_response'].success && res['google_response'].action === 'sendEmail') {
              emailjs.send('service_dak49oc', 'template_w6efls9', {
                name: this.contactForm.value.name,
                email: this.contactForm.value.email,
                subject: this.contactForm.value.subject,
                message: this.contactForm.value.message,
              }, '3vf7FKd29iwas1RLB')
                .then((result) => {
                  this.emailError = false;
                  this.emailSent = true;
                  send.disabled = false;
                  console.log(result.text);
                }, (error) => {
                  this.emailError = true;
                  this.emailSent = false;
                  send.disabled = false;
                  console.log(error.text);
                });
            } else if (!res['google_response'].success) {
              this.emailError = true;
              this.emailSent = false;
              send.disabled = false;
            }
          },
          (err) => {
            console.log('send email:', err);
            this.emailError = true;
            this.emailSent = false;
            send.disabled = false;
          } );
      });
  }

}
