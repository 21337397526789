import {InMemoryDbService} from 'angular-in-memory-web-api';
import * as businessData from '../../assets/mocks/business.json';
import * as tapList from '../../assets/mocks/taplist.json';
import * as ngrokUrl from '../../assets/mocks/ngrok.json';
import {Injectable} from '@angular/core';
import {Marker} from './model/marker';
import {Taplist} from './model/taplist';
import {Ngrok} from './model/ngrok';

@Injectable({
  providedIn: 'root',
})
export class InMemoryDataService implements InMemoryDbService {
  createDb() {
    const markers: Marker[] = JSON.parse(JSON.stringify(businessData));
    const taplists: Taplist[] = JSON.parse(JSON.stringify(tapList));
    const ngrok: Ngrok[] = JSON.parse(JSON.stringify(ngrokUrl));
    return {markers, taplists, ngrok};
  }
}
