import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import * as captcha from '../../../assets/mocks/ngrok.json';
import {Observable} from 'rxjs';
import {Ngrok} from '../model/ngrok';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private ngroktUrl = 'api/ngrok';

  constructor(
    private http: HttpClient) {
  }

  checkRecaptcha(url, body) {
      return this.http.post(url, body);
  }

  getNgrokUrl(): Observable<Ngrok> {
    return this.http.get<Ngrok>(this.ngroktUrl);
  }
}
